<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': pelayananActive}">
        <a href="#" class="nav-link" @click="pelayananActive = !pelayananActive">
          💻
          <p>
            Monitoring
          </p>
        </a>
        <ul class="nav nav-treeview">
          <li class="nav-item pl-2" v-if="props.akses.includes('rujukan_dashboard') || props.akses.includes('semua')">
            <router-link to="/rujukan-dashboard" class="nav-link">
              📃
              <p class="text-sm">Monitoring Pengendalian Rujukan</p>
            </router-link>
          </li>
        </ul>
      </li>
</template>

<script setup>
import { ref, defineProps} from 'vue'

const pelayananActive = ref(false)

const props = defineProps({
  akses: Array
})
</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>