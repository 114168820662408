<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h5 class="m-0 text-bold ">{{ title }}</h5>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item active text-bold">{{ title }}</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
</template>

<script>
  export default{
    props: ['title']
  }
</script>


<style scoped>
/* Header Styling */
.content-header {
  animation: fadeIn 0.6s ease-in-out;
}

/* Animasi saat halaman dimuat */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}




/* Responsiveness */
@media (max-width: 768px) {
  .page-title {
    font-size: 1.5rem;
  }
}
</style>