<template>
	<div>
		<LoadingScreen v-if="loading_screen" />
		<!-- Navbar -->
		  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
		    <!-- Left navbar links -->
		    <ul class="navbar-nav">
		      <li class="nav-item">
		        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
		      </li>
		    </ul>


		    <!-- Right navbar links -->
		    <ul class="navbar-nav ml-auto">
		      <!-- Notifications Dropdown Menu -->
		      <li class="nav-item dropdown" @click="dialogVisible = !dialogVisible">
		        <a class="nav-link" href="#">
		          <i class="far fa-bell"></i>
		          <span class="badge badge-warning navbar-badge">{{ notifikasi.pasien_berulang_tahun + notifikasi.jatuh_tempo + notifikasi.obat_segera_expired + notifikasi.pasien_akan_datang }}</span>
		        </a>
		      </li>
		      <li class="nav-item">
		        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
		          <i class="fas fa-expand-arrows-alt"></i>
		        </a>
		      </li>
			  <li class="nav-item dropdown">
				<a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
				<i v-if="!userImage" class="fas fa-user" style="font-size: 24px;"></i>
				<img v-else :src="userImage" alt="User Image" class="rounded-circle" style="width: 30px; height: 30px;">
				</a>
				<div class="dropdown-menu dropdown-menu-right">
					<!-- <a class="dropdown-item" :href="`/view-profil/${user.data.tbKaryawanId}`">
					<i class="fas fa-user mr-2"></i> Profil saya</a>
					<a class="dropdown-item" :href="`/edit-data-karyawan/${user.data.tbKaryawanId}`">
   					<i class="fas fa-edit mr-2"></i> Edit Profil saya</a> -->
					<a class="dropdown-item" href="/setting-user">
					<i class="fas fa-cog mr-2"></i> Setting Data User</a>
					<div class="dropdown-divider"></div>
					<a class="dropdown-item" @click="konfirmasiLogout">
					<i class="fas fa-sign-out-alt mr-2"></i> Logout ?</a>

				</div>
				</li>
		    </ul>
		  </nav>

		  

		  <el-dialog v-model="dialogVisible" title="Notifikasi" width="50%">
				<div>
					<el-collapse v-model="notifActive">
						<el-collapse-item name="4">
							<template #title><p class="text-md">Pasien Akan Datang <span class="badge badge-warning">{{notifikasi.pasien_akan_datang}}</span></p></template>
							<div>
								<table class="table table-bordered">
									<tr>
										<th>No</th>
										<th>Nama</th>
										<th>
											<input type="checkbox" v-model="checkAll" @click="toggleAll()">
										</th>
									</tr>
									<tr v-for="(item, index) in notifikasi.data_pasien_akan_datang" :key="index">
										<td style="width: 70px;">{{ index+1 }}</td>
										<td>{{ item.pasien?.nama }}</td>
										<td style="width: 50px;">
											<input type="checkbox" v-model="item.checked" @click="addNumber(item)"> 
										</td>
									</tr>
								</table>
								<div class="row d-flex justify-content-end">
									<button @click="handleSelectionChange" class="btn btn-success btn-sm d-flex align-items-center mr-2" disabled>
										<div>Send</div>
										<div class="spinner-border spinner-border-sm ml-2" v-if="loading.submitWa" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</button>
								</div>
							</div>
						</el-collapse-item>
						<el-collapse-item name="1">
							<template #title><p class="text-md">Pasien Berulang Tahun <span class="badge badge-warning">{{notifikasi.pasien_berulang_tahun}}</span></p></template>
							<div>
								<p class="block mt-2" style="cursor: pointer;" v-for="(row, index) in notifikasi.data_pasien_berulang_tahun" :key="index">
									<span @click="getDetailPasien(row.id)" class="text-primary">{{ row.nama }}</span>
								</p>
							</div>
						</el-collapse-item>
						<el-collapse-item name="2">
							<template #title><p class="text-md">Obat Segera Expired <span class="badge badge-warning">{{notifikasi.obat_segera_expired}}</span></p></template>
							<div>
								<p class="block mt-2" style="cursor: pointer;" v-for="(row, index) in notifikasi.data_obat_segera_expired" :key="index">
									<span @click="getDetailStok(row.id)" class="text-primary">{{ row.produk?.kode }} - {{ row.produk?.nama }}</span>
								</p>
							</div>
						</el-collapse-item>
						<el-collapse-item name="3">
							<template #title><p class="text-md">Faktur Jatuh Tempo <span class="badge badge-warning">{{notifikasi.jatuh_tempo}}</span></p></template>
							<div>
								<p class="block mt-2" style="cursor: pointer;" v-for="(row, index) in notifikasi.data_jatuh_tempo" :key="index">
									<span class="text-primary">Pemjualan ke {{ row.nama_pembeli }}  - {{ row.nomor_faktur }}</span>
								</p>
							</div>
						</el-collapse-item>
					</el-collapse>	
				</div>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogVisible = false">Cancel</el-button>
					</span>
				</template>
			</el-dialog>

			<el-dialog v-model="detailPasienVisible" title="Detail Pasien" width="50%" append-to-body>
				<div>
					<el-descriptions :column="2" v-if="pasien != null" v-loading="loading.pasien" border>
						<el-descriptions-item width="25%" label="Nomor Rekam Medis">{{pasien.nomor_rekam_medis}}</el-descriptions-item>
						<el-descriptions-item label="Golongan Darah">{{pasien.golongan_darah}}</el-descriptions-item>
						<el-descriptions-item label="Nama">{{pasien.nama}}</el-descriptions-item>
						<el-descriptions-item label="Tempat Lahir">{{pasien.tempat_lahir}}</el-descriptions-item>
						<el-descriptions-item label="Nomor Identitas">{{pasien.nomor_identitas}}</el-descriptions-item>
						<el-descriptions-item label="Tanggal Lahir">{{pasien.tanggal_lahir}}</el-descriptions-item>
						<el-descriptions-item label="Provinsi">{{pasien.provinsi}}</el-descriptions-item>
						<el-descriptions-item label="Agama">{{pasien.agama}}</el-descriptions-item>
						<el-descriptions-item label="Kota / Kabupaten">{{pasien.kota}}</el-descriptions-item>
						<el-descriptions-item label="Pendidikan">{{pasien.pendidikan}}</el-descriptions-item>
						<el-descriptions-item label="Kecamatan">{{pasien.kecamatan}}</el-descriptions-item>
						<el-descriptions-item label="Pekerjaan">{{pasien.pekerjaan}}</el-descriptions-item>
						<el-descriptions-item label="Desa">{{pasien.desa}}</el-descriptions-item>
						<el-descriptions-item label="Sumber Informasi">{{pasien.sumber_informasi}}</el-descriptions-item>
						<el-descriptions-item label="Alamat Lengkap">{{pasien.alamat_lengkap}}</el-descriptions-item>
						<el-descriptions-item label="Sosial Media">{{pasien.sosial_media}}</el-descriptions-item>
						<el-descriptions-item label="Nomor Telepon">{{pasien.telepon}}</el-descriptions-item>
						<el-descriptions-item label="Nomor Member">{{pasien.nomor_member}}</el-descriptions-item>
						<el-descriptions-item label="Jenis Kelamin">{{pasien.jenis_kelamin}}</el-descriptions-item>
						<el-descriptions-item label="Label Pasien">{{pasien.label_pasien}}</el-descriptions-item>
					</el-descriptions>
				</div>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="detailPasienVisible = false">Cancel</el-button>
					</span>
				</template>
			</el-dialog>

			<el-dialog v-model="detailStokVisible" title="Detail Stok" width="50%" append-to-body>
				<div>
					<el-descriptions :column="2" v-if="stok != null" v-loading="loading.stok" border>
						<el-descriptions-item width="25%" label="Kode">{{stok.produk.kode}}</el-descriptions-item>
						<el-descriptions-item label="Sisa Stok">{{stok.stok_akhir}}</el-descriptions-item>
						<el-descriptions-item label="Nama">{{stok.produk.nama}}</el-descriptions-item>
					</el-descriptions>
				</div>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="detailStokVisible = false">Cancel</el-button>
					</span>
				</template>
			</el-dialog>
		  <!-- /.navbar -->
	</div>
</template>

<script setup>
import LoadingScreen from '@/components/LoadingScreen'

import store from '@/store'
import Swal from 'sweetalert2'
import axios from 'axios'
import { ref, computed, onMounted } from 'vue'
import {laravel} from '../main'
// const user = computed(() => store.getters['auth/user'])
const cabang = computed(() => store.getters['auth/cabangs'])
const loading = ref({
	pasien : false,
	stok: false,
	submitWa: false
})

// Notifikasi
const notifikasi = ref('')
const dialogVisible = ref(false)
const notifActive = ref([])
const getDataNotifikasi = async () => {
	let { data } = await laravel.get(`api/notifikasi/${cabang.value.id}`)
	
	notifikasi.value = data.data
}

const pasien = ref(null)
const detailPasienVisible = ref(false)
const getDetailPasien = async (id) => {
	loading.value.pasien = true
	detailPasienVisible.value = true
	let { data } = await laravel.get(`api/pasien/getWhereId/${id}/${cabang.value.tipe}`)
	
	if(data != 'kosong') {
		pasien.value = data
	
		pasien.value.tanggal_lahir = pasien.value.tanggal_lahir.slice(0, 10)
		
		loading.value.pasien = false
	}
}

const stok = ref(null)
const detailStokVisible = ref(false)
const getDetailStok = async (id) => {
	loading.value.stok = true
	detailStokVisible.value = true
	let { data } = await laravel.get(`api/stok_produk/getDetailStok/${id}`)
	
	if(data != 'kosong') {
		stok.value = data.data
		loading.value.stok = false
		console.log(stok.value)
	}
}

const loading_screen = ref(false)
const konfirmasiLogout = () => {
	Swal.fire({
		text: 'Anda ingin logout?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: `Ya`,
		cancelButtonText: `Batal`,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
		logout()
		} 
	})
	}

const logout = async () => {
	loading_screen.value = true
	await store.dispatch('auth/logout')
}

// WA BLAST
const checkAll = ref()
const toggleAll = () => {
    let check = notifikasi.value.data_pasien_akan_datang.every(item => item.checked)
    notifikasi.value.data_pasien_akan_datang.forEach(item => {
        item.checked = !check
        if (!check == true) {
            dataKunjunganSelect.value.push(item)
        } else {
            dataKunjunganSelect.value = []
        }
    })
}

const dataKunjunganSelect = ref([])
const addNumber = (item) => {
    const index = dataKunjunganSelect.value.findIndex(i => i.id === item.id);
        if (index === -1) {
            // Jika tidak ada, tambahkan ke array
            dataKunjunganSelect.value.push(item);
        } else {
            // Jika sudah ada, hapus dari array
            dataKunjunganSelect.value.splice(index, 1);
        }
        if (dataKunjunganSelect.value.length == 0) {
            checkAll.value = false
        }
        if (dataKunjunganSelect.value.length == notifikasi.value.data_pasien_akan_datang.length) {
            checkAll.value = true
        } else {
            checkAll.value = false
        }
}

const handleSelectionChange = () => {
	loading.value.submitWa = true
	dataKunjunganSelect.value.forEach(async (element) => {
		await axios.post(`whatsapp_blast/sendMessage-template-sec`, {
			payload: {
				nama: element.pasien?.nama,
				nomor: element.pasien?.nomor_selular,
			}
		}).then(response => {
			loading.value.submitWa = false
			dialogVisible.value = false
			console.log('Success:', response);
			Swal.fire({
                title: 'Berhasil!',
                text: `Berhasil dikirim`,
                icon: 'success',
                showConfirmButton: true,
            })
		}).catch(error => {
			loading.value.submitWa = false
			console.error('Error:', error.response)
			Swal.fire({
                title: 'Gagal!',
                text: `Gagal dikirim`,
                icon: 'error',
                showConfirmButton: true,
            })
		}).finally(() => {
			element.checked = false
			checkAll.value = false
		})
	});
}

onMounted(() => {
	getDataNotifikasi()
})
</script>

<style scoped>
.navbar {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #dfdfdf;
  box-shadow: 0 4px 8px rgba(188, 228, 243, 0.648);
}

.nav-link {
  color: #000000 !important;
  transition: color 0.3s, transform 0.3s;
}

.nav-link:hover {
  color: #00e5ff !important;
  transform: scale(1.1);
}

.navbar-badge {
  background-color: #ff4081;
  font-size: 0.75rem;
}

.nav-item .dropdown-menu {
  background: rgba(255, 255, 255, 0.2); /* Warna putih dengan transparansi */
  border: none;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px); /* Efek blur */
  border-radius: 8px; /* Menambahkan sudut membulat */
}

.dropdown-item {
  color: #000000 !important;
  transition: background 0.3s;
}

.dropdown-item:hover {
  background: rgba(52, 52, 52, 0.3); /* Warna saat di-hover dengan efek transparansi */
}

.rounded-circle {
  border: 2px solid #00e5ff;
}

.dialog-footer .el-button {
  background: #1f1c2c;
  color: #f0f0f0;
  border: 1px solid #00e5ff;
}

.dialog-footer .el-button:hover {
  background: #00e5ff;
  color: #1f1c2c;
}

</style>
