<template>
	<li class="nav-item" v-bind:class="{ 'menu-is-opening menu-open': marketingActive }">
		<a href="#" class="nav-link" @click="marketingActive = !marketingActive">
			<i class="nav-icon fas fa-ad"></i>
			<p>
				Marketing
				<i class="right fas fa-angle-left"></i>
			</p>
		</a>
		<ul class="nav nav-treeview">
			<li class="nav-item pl-2" v-if="props.akses.includes('whatsapp_blast') || props.akses.includes('semua')">
				<router-link to="/whatsapp-blast" class="nav-link">
					<i class="far fa-circle nav-icon"></i>
					<p class="text-sm">WhatsApp Blast</p>
				</router-link>
			</li>
			<li class="nav-item pl-2" v-if="props.akses.includes('template_pesan') || props.akses.includes('semua')">
				<router-link to="/template-pesan" class="nav-link">
					<i class="far fa-circle nav-icon"></i>
					<p class="text-sm">Template Pesan</p>
				</router-link>
			</li>
			<!-- <li class="nav-item">
			<a href="pages/charts/flot.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>SMS</p>
			</a>
			</li>
			<li class="nav-item">
			<a href="pages/charts/inline.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>Whatsapp</p>
			</a>
			</li>
			<li class="nav-item">
			<a href="pages/charts/inline.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>Set up Mobile Phone</p>
			</a>
			</li>
			<li class="nav-item">
			<a href="pages/charts/inline.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>Bridging denga Website Compro</p>
			</a>
			</li>
			<li class="nav-item">
			<a href="pages/charts/inline.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>Reseller</p>
			</a>
			</li> -->
		</ul>
	</li>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const marketingActive = ref(false)

const props = defineProps({
	akses: Array
})

</script>

<style>
.menu-is-open {
	background-color: #494E53;
	transition: all 3s;
}

.show {
	display: block;
}
</style>