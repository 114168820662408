<template>
	<div>
		<!-- Main Sidebar Container -->
		  <aside class="main-sidebar sidebar-dark-primary elevation-4" style="overflow-x: hidden;overflow-y: scroll;">
		    <!-- Sidebar -->
		    <div class="sidebar" >
		      <!-- Sidebar user panel (optional) -->
		      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
		        <!-- <div class="image">
		          <img src="/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
		        </div> -->
		        <div class="info" style="width: 100%;">
				  <div class="w-full d-flex flex-column mb-2" style="height: auto;">
				 	<img :src="image ? image : defaultImage" alt="" style="width: 100px;height: 100px;display: block;margin-left: auto;margin-right: auto;">
				  </div>
		          <a href="#" class="d-block text-white text-center">{{ cabang.data.nama }}</a>
				  <p class="text-center mt-2" style="color: #CCCCCC;">{{ user.data.name }}</p>
				  <div class="d-flex justify-content-center">
					<ul class="navbar-nav">
					<li class="nav-item dropdown">
						<router-link :to="`/chat-list`" class="nav-link">
							<i class="fa fa-comment"></i>
							<span class="badge badge-warning navbar-badge" v-if="totalNotifChat != 0">{{ totalNotifChat }}</span>
						</router-link>
					</li>
					</ul>
				  </div>
		        </div>
		      </div>
		      <!-- SidebarSearch Form -->
		      <div class="form-inline">
		        <div class="input-group" data-widget="sidebar-search">
		          <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
		          <div class="input-group-append">
		            <button class="btn btn-sidebar">
		              <i class="fas fa-search fa-fw"></i>
		            </button>
		          </div>
		        </div>
		      </div>

		      <!-- Sidebar Menu -->
		      <nav class="mt-2">
		        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
		          <!-- Add icons to the links using the .nav-icon class
		               with font-awesome or any other icon font library -->
		          
		          <!-- Menu -->
		          <Dashboard v-if="cabang.data.kode_cabang != 'IDI'" />
		          <Administrasi :akses="hak_akses_store" v-if="credential.master && hak_akses.master && cabang.data.kode_cabang != 'IDI'" />
		          <RekamMedis :akses="hak_akses_store" v-if="credential.rekam_medis && hak_akses.rekam_medis && cabang.data.kode_cabang != 'IDI'" />
		          <Farmasi :akses="hak_akses_store" v-if="credential.farmasi && hak_akses.farmasi && cabang.data.kode_cabang != 'IDI'" />
		          <PelayananKlinik :akses="hak_akses_store" v-if="credential.pelayanan_klinik && hak_akses.pelayanan && cabang.data.kode_cabang != 'IDI'" />
		          <Akuntansi :akses="hak_akses_store" v-if="credential.akuntansi && hak_akses.akuntansi && cabang.data.kode_cabang != 'IDI'" />
		          <HRD :akses="hak_akses_store" v-if="credential.hrd && hak_akses.hrd && cabang.data.kode_cabang != 'IDI'" />
		          <Marketing :akses="hak_akses_store" v-if="credential.marketing && hak_akses.marketing && cabang.data.kode_cabang != 'IDI'" />
		          <Laporan :akses="hak_akses_store" v-if="credential.laporan && hak_akses.laporan && cabang.data.kode_cabang != 'IDI'" />
		          <AnalisisStatistik :akses="hak_akses_store" v-if="credential.analisis && hak_akses.analisis && cabang.data.kode_cabang != 'IDI'" />
		          <Anjungan :akses="hak_akses_store" v-if="credential.console && hak_akses.console && cabang.data.kode_cabang != 'IDI'" />
		          <Monitoring :akses="hak_akses_store" v-if="credential.monitoring && hak_akses.monitoring && cabang.data.kode_cabang != 'IDI'" />
		          <SatuSehat :akses="hak_akses_store" v-if="credential.satu_sehat && hak_akses.satu_sehat && cabang.data.kode_cabang != 'IDI'" />
		          <Membership v-if="cabang.data.kode_cabang == 'IDI'" />
		          <!-- End Menu -->
		        </ul>
		      </nav>
		      <!-- /.sidebar-menu -->
		    </div>
		    <!-- /.sidebar -->
  </aside>
	</div>
</template>

<script setup>
import Dashboard from './menu/Dashboard.vue' 
import Administrasi from './menu/Administrasi.vue'
import RekamMedis from './menu/RekamMedis.vue'
import Farmasi from './menu/Farmasi.vue'
import PelayananKlinik from './menu/PelayananKlinik.vue'
import Akuntansi from './menu/Akuntansi.vue'
import HRD from './menu/HRD.vue'
import Marketing from './menu/Marketing.vue'
import Laporan from './menu/Laporan.vue'
import AnalisisStatistik from './menu/AnalisisStatistik.vue'
import Anjungan from './menu/Anjungan.vue'
import SatuSehat from './menu/SatuSehat.vue'
import Membership from './menu/Membership.vue'
import Monitoring from './menu/Monitoring.vue'
import Swal from 'sweetalert2'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { computed, onMounted, reactive, ref } from 'vue'
import axios from 'axios'
import {laravel, scoket_io, urlSocket} from '@/main'
import router from '@/router';
import io from 'socket.io-client';
const user = computed(() => store.getters['auth/user'])
const cabang = computed(() => store.getters['auth/cabang'])
const hak_akses_store = computed(() => store.getters['auth/hak_akses'])
const karyawan = computed(() => store.getters['auth/karyawan'])
// CREDENTIAL PUSAT
const userData = ref({})
const passwordExpiry = ref({
  isExpiringSoon: false,
  daysLeft: 0
})


const fetchUserData = async () => {
  try {
    const response = await axios.get(`user/${user.value.data.id}`)
    userData.value = response.data.data
    passwordExpiry.value = response.data.passwordExpiry

    // Check if the password is expiring soon and display notification
    if (passwordExpiry.value.isExpiringSoon) {
      Swal.fire({
        title: 'Password Expiry Alert!',
        text: `Harap Segara Ganti Password dikarnakan Password Anda akan Expired Dalam ${passwordExpiry.value.daysLeft} Hari.`,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Change Password',
        timer: 98000,
        timerProgressBar: true,
		allowOutsideClick: false,
        allowEscapeKey: false,
        // didOpen: () => {
        //   Swal.showLoading()
        // }
    }).then((result) => {
        if (result.isConfirmed) {
          // Assuming you're using Vue Router
          router.push('/setting-user')
        }
      })
    }
  } catch (error) {
    console.error('Failed to fetch user data:', error)
    Swal.fire({
      title: 'Error!',
      text: 'Failed to load user data.',
      icon: 'error',
      confirmButtonText: 'OK'
    })
  }
}



const logo = computed(() => store.getters['auth/logo'])
const image = ref()
image.value = logo.value.config.baseURL + '/' + logo.value.data.data.logo

const { credential, menuPusat, menuCabang } = useCredential()

const get_cabang = async () => {
	if (cabang.value.data.jenis == 'pusat') {
		menuPusat()
	} else {
		menuCabang()
	}
}
// END CREDENTIAL PUSAT

const defaultImage = ref(`https://ui-avatars.com/api/?name=${cabang.value.data.nama}&&background=0D8ABC&color=fff`)

const { hak_akses, get_hak_akses } = useCredentialAccess(user)

onMounted(() => {
	get_cabang()
	get_hak_akses()
	fetchUserData()
	getMessageList()
})

function useCredentialAccess (user) {
	const hak_akses = reactive({
		'dashboard' : true,
		'master': false,
		'rekam_medis' : false,
		'farmasi' : false,
		'pelayanan_klinik': false,
		'akuntansi' : false,
		'hrd' : false,
		'pelayanan' : false,
		'laporan' : false,
		'analisis' : false,
		'console' : false,
		'satu_sehat' : false,
		'marketing' : false,
		'monitoring' : false,
	})

	const get_hak_akses = async () => {
		let { data } = await laravel.get(`api/hak_akses/get_distinct/${user.value.data.id}`)
		if (data == 'kosong') {	
			full_access()
		} else {
			data.filter(generate_access)		
		}
	}

	const generate_access = (data) => {
		if (data.menu.menu == 'master') {
			hak_akses.master = true
		} else if (data.menu.menu == 'rekam_medis') {
			hak_akses.rekam_medis = true
		} else if (data.menu.menu == 'farmasi') {
			hak_akses.farmasi = true
		} else if (data.menu.menu == 'akuntansi') {
			hak_akses.akuntansi = true
		} else if (data.menu.menu == 'hrd') {
			hak_akses.hrd = true
		} else if (data.menu.menu == 'pelayanan') {
			hak_akses.pelayanan = true
		} else if (data.menu.menu == 'laporan_laporan') {
			hak_akses.laporan = true
		} else if (data.menu.menu == 'analisis_statistik') {
			hak_akses.analisis = true
		} else if (data.menu.menu == 'console_box') {
			hak_akses.console = true
		} else if (data.menu.menu == 'satu_sehat') {
			hak_akses.satu_sehat = true
		} else if (data.menu.menu == 'marketing') {
			hak_akses.marketing = true
		} else if (data.menu.menu == 'monitoring') {
			hak_akses.monitoring = true
		}
	}

	const full_access = () => {
		hak_akses.dashboard = true
		hak_akses.rekam_medis = true
		hak_akses.farmasi = true
		hak_akses.pelayanan_klinik = true
		hak_akses.akuntansi = true
		hak_akses.hrd = true
		hak_akses.pelayanan = true
		hak_akses.laporan = true
		hak_akses.master = true
		hak_akses.analisis = true
		hak_akses.console = true
		hak_akses.satu_sehat = true
		hak_akses.marketing = true
		hak_akses.monitoring = true
	}

	return { hak_akses, get_hak_akses }
}

function useCredential () {
	const credential = reactive({
		'dashboard': false,
		'master': false,
		'rekam_medis' : false,
		'farmasi' : false,
		'pelayanan_klinik': false,
		'akuntansi' : false,
		'hrd' : false,
		'pelayanan' : false,
		'laporan' : false,
		'analisis' : false,
		'console' : false,
		'satu_sehat' : false,
		'marketing' : false,
		'monitoring' : false,
	})

	const menuPusat = () => {
		credential.dashboard = true
		credential.master = true
		credential.rekam_medis = true
		credential.farmasi = true
		credential.pelayanan_klinik = false
		credential.akuntansi = true
		credential.hrd = true
		credential.pelayanan = true
		credential.laporan = true
		credential.analisis = true
		credential.console = true
		credential.satu_sehat = true
		credential.marketing = true
		credential.monitoring = true
	}

	const menuCabang = () => {
		credential.dashboard = true
		credential.master = true
		credential.rekam_medis = true
		credential.farmasi = true
		credential.pelayanan_klinik = true
		credential.akuntansi = true
		credential.hrd = true
		credential.pelayanan = true
		credential.laporan = true
		credential.analisis = true
		credential.console = true
		credential.satu_sehat = true
		credential.marketing = true
		credential.monitoring = true
	}

	return { credential, menuPusat, menuCabang }
}


const socket = io(urlSocket.baseUrl, {
    withCredentials: true
});

socket.on('notification', (data) => {
    if (karyawan.value.data.id == data.recipientId) {
		axios.get(`karyawan/getById/${data.senderId}`)
        .then((response) => {
            notifyNewMessage(data.message, response.data.data.nama_lengkap);
        })
        let cvData = {selfId: data.recipientId, otherId: data.senderId}
        getChatByStatus(cvData)
    }
});

socket.on('read message', (data) => {
    if (karyawan.value.data.id == data.senderId) {
        let cvData = {selfId: data.senderId, otherId: data.recipientId}
        getChatByStatus(cvData)
    }
});

const notifyNewMessage = (message, sender) => {
    // Cek apakah browser mendukung notifikasi
    if (!("Notification" in window)) {
      alert("Browser Anda tidak mendukung notifikasi.");
    } else if (Notification.permission === "granted") { // Jika izin notifikasi telah diberikan
      // Tampilkan notifikasi
      new Notification(`${sender}`, {
        body: `${message}`,
        icon: `${image.value}`,
      });
    } else if (Notification.permission !== "denied") { // Jika izin belum diberikan, minta izin
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          // Tampilkan notifikasi
          new Notification(`${sender}`, {
            body: `${message}`,
            icon: `${image.value}`,
          });
        }
      });
    }
    ElMessage({
        message: `
            <div style="display: flex; align-items: center; gap: 15px;">
                <div style="font-size: 24px; font-weight: bold; color: #409eff;">
                    🔔 Pesan Baru!
                </div>
                <div>
                    <div style="font-size: 20px; font-weight: bold;">${sender}</div>
                    <div style="font-size: 18px;">"${message}"</div>
                </div>
            </div>
        `,
        type: 'info',
        duration: 5000, // Durasi notifikasi
        dangerouslyUseHTMLString: true, // Mendukung HTML di dalam pesan
        customClass: 'custom-message-bounce', // Kelas custom untuk styling tambahan
        center: true // Pusatkan notifikasi
    });
};

const getMessageList = async () => {
    // Fetch the chat history\
	try {
		const response = await scoket_io.get(`/chat/chat_list/${karyawan.value.data.id}`);
		let data = response.data.data
		if (data.length != 0) {
			data.forEach(element => {
				getChatByStatus(element)
			});
		}
	} catch (error) {
		console.error('Failed to fetch chat history:', error);
	}
}

const chatUnread = ref([])
const totalNotifChat = computed(() => {
	return chatUnread.value.reduce((acc, item) => acc + item.jumlah_chat, 0)
})


async function getChatByStatus(data) {
    await scoket_io.get(`chat/get_by_status/${data.selfId}/${data.otherId}`)
    .then((response) => {
		if (chatUnread.value.length == 0) {
            response.data.data.forEach((item) => {
                chatUnread.value.push({
                    "id": item.senderId,
                    "jumlah_chat": response.data.data.length
                })
            })
        } else {
            let isDuplicate = false
            chatUnread.value.forEach((item) => {
                if (item.id == data.otherId) {
                    isDuplicate = true;
                    item.jumlah_chat = response.data.data.length
                }
            })
            if (!isDuplicate) {
                response.data.data.forEach((item) => {
                    chatUnread.value.push({
                        "id": item.senderId,
                        "jumlah_chat": response.data.data.length
                    })
                })
            }
        }
        let count = 0;
        for (let i = 0; i < chatUnread.value.length; i++) {
            if (chatUnread.value[i].id == data.otherId) {
                count++;
                if (count > 1) {
                    chatUnread.value.splice(i, 1);
					
                    i--;
                }
            }
        }
		
    })
}
	
</script>




<style scoped>
	.menu-is-open{
		background-color: #494E53;
		transition: all 3s;
	}

	.show{
		display: block;
	}

	/*Scroll Bar*/
	/* width */
	::-webkit-scrollbar {
	  width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	  background: #494E53;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #555;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: #888;
	}

	.circular_image {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	overflow: hidden;
	background-color: blue;
	/* commented for demo
	float: left;
	margin-left: 125px;
	margin-top: 20px;
	*/
	
	/*for demo*/
	display:inline-block;
	vertical-align:middle;
	}
	.circular_image img{
	width:100%;
	}



	/* Custom class untuk ElMessage */
.custom-message-bounce {
    animation: bounce 0.5s ease-in-out, fade-out 1s ease-out 4s; /* Bounce di awal, fade-out di akhir */
    border: 3px solid #409eff; /* Border biru */
    border-radius: 15px; /* Membulatkan sudut */
    font-family: Arial, sans-serif; /* Ganti font */
    background-color: #e6f7ff; /* Warna background */
    padding: 20px; /* Tambahkan padding */
    font-size: 20px; /* Perbesar font */
    color: #333; /* Warna teks */
    max-width: 400px; /* Atur lebar maksimum */
    text-align: left; /* Atur teks ke kiri */
}

/* Animasi bounce */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

/* Animasi fade-out */
@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

</style>