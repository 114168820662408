<template>
  <div class="login-container">
    <div class="login-left">
      <div class="background">
        <!-- Using common classes to minimize redundancy -->
        <span class="ball"></span>
        <span class="ball"></span>
        <span class="ball"></span>
        <span class="ball"></span>
        <span class="ball"></span>
        <span class="ball"></span>
      </div>
    </div>



    <div class="login-right">
      <div v-if="resetToken">
        <h4 class="text-center text-bold mb-4">Reset Password Anda</h4>
        <form @submit.prevent="handleResetPassword">
            <div class="input-group">
              <input :type="passwordFieldType" v-model="newPassword" class="form-control" placeholder="New password" required />
              <span class="toggle-icon" @click="togglePasswordVisibility">
                <i :class="{'fas fa-eye-slash': passwordFieldType === 'password', 'fas fa-eye': passwordFieldType === 'text'}"></i>
              </span>
            </div>
            <div class="input-group">
              <input :type="passwordFieldType" v-model="confirmPassword" class="form-control" placeholder="Confirm password" required />
            </div>
            <button class="btn-reset">Reset Password</button>
          </form>
        </div>

        <div v-else>
          <div class="logo-container app-title">
            <img src="/assets/solumed.gif" alt="Logo" width="200" />
          </div>
        
          <br>
          <h4 class="login-title text-center">Sistem Informasi Managemen</h4>
        <h6 class="login-subtitle text-center">Klinik, Apotek, Praktek Dokter & Nakes Lainnya</h6>
          <br>
          <br>
          <h4 class="login-title text-center">{{ greeting }}</h4>
          <p class="login-subtitle text-center">Silakan Login dengan Informasi Anda</p>
          <form @submit.prevent="handleLogin">
            <div class="input-group">
              <input type="email" v-model="credential.email" class="form-control" placeholder="Email" required />
              <span class="icon"><i class="fas fa-envelope"></i></span>
            </div>
            <div class="input-group">
              <input type="password" v-model="credential.password" class="form-control" placeholder="Password" required />
              <span class="icon"><i class="fas fa-lock"></i></span>
            </div>
            <div class="input-group captcha-group">
              <input :value="captcha.question" class="form-control" disabled />
              <input type="text" v-model="captchaAnswer" class="form-control" placeholder="Answer" required />
            </div>
            <div class="form-options">
              <label class="remember-me">
                <input type="checkbox" v-model="rememberMe"/> Remember me
              </label>
              <a href="#" @click="toggleForgotPassword" class="forgot-password">Lupa Password?</a>
            </div>
                <button :class="['btn-login', { loading: loading }]" @click="handleLogin">
                <div v-if="!loading">Log In</div>
                <div v-if="loading" class="spinner"></div> 
              </button>
            <div class="social-login">
              <p class="text-sm text-center text-bold"> © 2021 Made With ❤️ Solumedis Sofware Solutions. All rights reserved <br>
                v4.18.14
              </p>
            </div>
        </form>
        <div v-if="showForgotPassword" class="modal-overlay" @click.self="toggleForgotPassword">
            <div class="modal-content">
              <h4 class="modal-title text-bold">Lupa Password</h4>
              <select v-model="selectedBranch" class="form-control mt-2">
                <option disabled value="">Pilih Cabang</option>
                <option v-for="branch in branches" :key="branch.id" :value="branch.id">
                  {{ branch.name }}
                </option>
              </select>
              <input type="email" v-model="forgotEmail" class="form-control mt-2" placeholder="Email Pemulihan Anda" />
              <button class="btn btn-primary btn-block text-center mt-2" @click.stop="submitForgotPassword">Kirim Link Reset</button>
              <button class="btn btn-secondary mt-2" @click="toggleForgotPassword">Tutup</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import router from '@/router';
import { ref, reactive,onMounted,computed  } from 'vue';
import Swal from 'sweetalert2';
import store from '@/store';
import axios from 'axios';
export default {
  setup() {
    const loading = ref(false);
    const resetToken = ref(null);
    const newPassword = ref('');
    const confirmPassword = ref('');
    const showForgotPassword = ref(false);
    const passwordFieldType = ref('password');
    const rememberMe = ref(false);
    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
    };
    const forgotEmail = ref('');
    const credential = reactive({
      email: '',
      password: ''
    });

    const greeting = computed(() => {
    const hour = new Date().getHours();
    if (hour < 12) {
      return 'Selamat Pagi';
    } else if (hour < 15) {
      return 'Selamat Siang';
    } else if (hour < 18) {
      return 'Selamat Sore';
    } else {
      return 'Selamat Malam';
    }
  });


    const captcha = ref(generateCaptcha());
    const captchaAnswer = ref('');
    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      resetToken.value = urlParams.get('token');

      const storedEmail = localStorage.getItem('rememberEmail');
      if (storedEmail) {
        credential.email = storedEmail;
        rememberMe.value = true;
      }

      const screenWidth = window.innerWidth;
      if (screenWidth <= 1024) { 
        document.querySelector('.login-right').scrollIntoView({ behavior: 'smooth' });
      }
    });

    const handleResetPassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        Swal.fire('Passwords do not match', '', 'error');
        return;
      }
      try {
        const response = await axios.post('user/reset-password', {
          token: resetToken.value,
          newPassword: newPassword.value
        });
        console.log('Server Response:', response);
        Swal.fire('Password reset successfully', '', 'success').then(() => {
          resetToken.value = null;
          newPassword.value = '';
          confirmPassword.value = '';
          router.push('/login');
        });
      } catch (error) {
        Swal.fire('Failed to reset password', '', 'error');
      }
    };

    function toggleForgotPassword() {
      showForgotPassword.value = !showForgotPassword.value;
    }
    const branches = ref([
      { id: 2, name: 'Klinik Pratama Ratnasari Sehat' },
      { id: 3, name: 'Klinik Ratna Sari Sehat Ciwidey' },
      { id: 4, name: 'Klinik Seroja Medika Cangkuang' },
      { id: 6, name: 'Klinik Ratnasari Sehat Pasirjambu' },
      { id: 9, name: 'Klinik DPP M Yossa Effendi' },
      { id: 10, name: 'Klinik Sehat Medika Utama' },
      { id: 11, name: 'Klinik Citere Medikal Center' },
      { id: 15, name: 'Klinik Cantik Ratnasari (RSA)' },
      { id: 16, name: 'RSA Beautycare Ciwidey' },
      { id: 18, name: 'RSA Beautycare Klinik Pangalengan' },
    ]);
    const selectedBranch = ref(null);

    async function submitForgotPassword() {
  if (!selectedBranch.value) {
    Swal.fire({
      text: 'Tolong Pilih Cabang Terlebih Dahulu',
      icon: 'warning',
      showConfirmButton: true
    });
    return;
  }

  try {
    const response = await axios.post(`/user/forgot-password/${selectedBranch.value}`, {
      email_pemulihan: forgotEmail.value
    });
    Swal.fire({
      text: response.data.message,
      icon: 'success',
      timer: 3000
    });
    showForgotPassword.value = false; // Hide form after submission
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      text: 'Failed to send reset link',
      icon: 'error',
      showConfirmButton: true
    });
  }
}

    function generateCaptcha() {
      const num1 = Math.floor(Math.random() * 10);
      const num2 = Math.floor(Math.random() * 10);
      return {
        question: `${num1} + ${num2}`,
        answer: num1 + num2
      };
    }

    const handleLogin = async () => {
      if (parseInt(captchaAnswer.value) !== captcha.value.answer) {
        Swal.fire({
          text: 'Incorrect captcha answer',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500
        });
        captcha.value = generateCaptcha(); // Reset captcha
        captchaAnswer.value = ''; // Clear answer field
        return;
      }

      loading.value = true;
      try {
        const response = await store.dispatch('auth/login', credential);
        console.log(response);
        if (response === 'wrong_credential') {
          Swal.fire({
            text: 'Email atau Password Salah',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          if (rememberMe.value) {
            localStorage.setItem('rememberEmail', credential.email);
          } else {
            localStorage.removeItem('rememberEmail');
          }
          router.push('/absensi-karyawan');
        }
      } catch (error) {
        console.error('Login Error:', error);
        Swal.fire({
          text: 'Login failed due to an unexpected error. Please try again.',
          icon: 'error',
          showConfirmButton: true
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      handleLogin,
      submitForgotPassword,
      toggleForgotPassword,
      showForgotPassword,
      forgotEmail,
      credential,
      handleResetPassword,
      newPassword,
      confirmPassword,
      resetToken,
      greeting ,
      loading,
      captcha,
      captchaAnswer,
      branches,
      selectedBranch,
      passwordFieldType,
      togglePasswordVisibility,
      rememberMe
    };
  }
};
</script>


<style scoped>
/* Main container styling */
.login-container {
  display: flex;
  min-height: 100vh;
  background-color: #c2dff864;
}
.login-left {
  flex: 1;
  position: relative;
  background: linear-gradient(to bottom, #87ceeb, #b0e0e6); /* Langit biru cerah */
  overflow: hidden;
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #1d1f25;
  backdrop-filter: blur(10px);
  overflow: hidden;
}

.ball {
  position: absolute;
  width: 20vmin;
  height: 20vmin;
  border-radius: 50%;
  backface-visibility: hidden;
  animation: move linear infinite;
}

.ball:nth-child(odd) {
    color: #1356fe;
}

.ball:nth-child(even) {
    color: #f22614;
}

/* Using a custom attribute for variability */
.ball:nth-child(1) {
  top: 77%;
  left: 88%;
  animation-duration: 40s;
  animation-delay: -3s;
  transform-origin: 16vw -2vh;
  box-shadow: 40vmin 0 5.703076368487546vmin currentColor;
}
.ball:nth-child(2) {
  top: 42%;
  left: 2%;
  animation-duration: 53s;
  animation-delay: -29s;
  transform-origin: -19vw 21vh;
  box-shadow: -40vmin 0 5.17594621519026vmin currentColor;
}
.ball:nth-child(3) {
  top: 28%;
  left: 18%;
  animation-duration: 49s;
  animation-delay: -8s;
  transform-origin: -22vw 3vh;
  box-shadow: 40vmin 0 5.248179047256236vmin currentColor;
}
.ball:nth-child(4) {
  top: 50%;
  left: 79%;
  animation-duration: 26s;
  animation-delay: -21s;
  transform-origin: -17vw -6vh;
  box-shadow: 40vmin 0 5.279749632220298vmin currentColor;
}
.ball:nth-child(5) {
  top: 46%;
  left: 15%;
  animation-duration: 36s;
  animation-delay: -40s;
  transform-origin: 4vw 0vh;
  box-shadow: -40vmin 0 5.964309466052033vmin currentColor;
}
.ball:nth-child(6) {
  top: 77%;
  left: 16%;
  animation-duration: 31s;
  animation-delay: -10s;
  transform-origin: 18vw 4vh;
  box-shadow: 40vmin 0 5.178483653434181vmin currentColor;
}
.ball:nth-child(7) {
  top: 22%;
  left: 17%;
  animation-duration: 55s;
  animation-delay: -6s;
  transform-origin: 1vw -23vh;
  box-shadow: -40vmin 0 5.703026794398318vmin currentColor;
}
.ball:nth-child(8) {
  top: 41%;
  left: 47%;
  animation-duration: 43s;
  animation-delay: -28s;
  transform-origin: 25vw -3vh;
  box-shadow: 40vmin 0 5.196265905749415vmin currentColor;
}


.logo-container img {
  margin-bottom: 20px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 0 30px #8fe3ffb1, 0 0 60px #8fe3ffab, 0 0 90px #8fe3ffa0; 
}

.app-title, .app-subtitle {
  text-align: center;
  color: #333;
  margin-top: 10px;
  
}



.login-right {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  max-height: 97vh; /* Batasan tinggi maksimal */
  overflow-y: auto;
  width: 750px; /* Ukuran box */
  max-width: 90%; /* Agar tetap proporsional di layar kecil */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.191) 0%,
    #f0f8ff13 40%, /* Warna transisi putih ke biru muda */
    rgba(97, 209, 246, 0.271) 80%,
    #87cefa41 90%, /* Warna transisi biru muda ke biru tua */
    rgba(249, 249, 249, 0.09) 100%
  );

  backdrop-filter: blur(1px);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 10px #8fe3ffb1, 0 0 20px #8fe3ffab, 0 0 30px #8fe3ffa0; 
  padding: 40px;
  color: #333;
  transition: all 0.3s ease-in-out;
}

/* Hapus efek zoom saat hover */
.login-right:hover {
  transform: translate(50%, -50%);
  box-shadow: 0 0 40px #8fe3ffb1, 0 0 50px #8fe3ffab, 0 0 60px #8fe3ffa0; 
}

.login-title {
  font-size: 24px;
  font-weight: bold;
  color: #fffdfd;
  margin-bottom: 10px;
}

.login-subtitle {
  color: #ffffff;
  margin-bottom: 20px;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15); /* Semi-transparent background */
  border-radius: 10px;
  backdrop-filter: blur(5px); /* Glass effect */
  box-shadow: 0 0 5px rgba(143, 227, 255, 0.5); /* Soft neon glow */
  transition: all 0.3s ease-in-out;
}
.input-group:hover {
  box-shadow: 0 0 10px #8fe3ff, 0 0 20px #8fe3ff, 0 0 30px #8fe3ff; /* Stronger glow on hover */
}

.input-group .form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
}

.icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #6c757d;
}

.form-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.remember-me input, label {
  margin-right: 5px;
  color: #ffffff
}

.forgot-password {
  color: #ffffff;
  text-decoration: none;
}

.btn-login, .btn-reset {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #007bff, #00d4ff);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  font-weight: bold;
}

.btn-login:hover {
  background: linear-gradient(135deg, #00d4ff, #007bff);
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.4);
}
/* Social Login Section */
.social-login {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  color: #ffffff;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.social-icon {
  color: #ffffff;
  background-color: #6c757d;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
}

.social-icon:hover {
  background-color: #5a6268;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: none;
}


.btn-login.loading {
  background-color: #3b5bce; /* Change background color during loading */
}


.btn-login.loading .spinner {
  display: block; /* Show spinner when loading */
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* @media (max-width: 1024px) {
  .login-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login-left {
    display: none; 
  }

  .login-right {
    max-width: 90%;
    padding: 20px;
  }
}

.illustration-image {
  width: 100% !important;
  height: 100% !important; 
  object-fit: cover;
} */


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1010;
}

.modal-title {
  margin-bottom: 15px;
  text-align: center;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #5a6268;
}


</style>